import classNames from 'classnames'
import React from 'react'
import InputWithMask from 'components/InputWithMask'
import type { Component } from 'constants/types'

const digitRegex = /\d/
const separator = '-'

const mask = (value) => {
  if (value.charAt(0) === '+' || value.charAt(0) === '1') {
    // 1-###-###-####
    return [
      /1/,
      separator,
      digitRegex,
      digitRegex,
      digitRegex,
      separator,
      digitRegex,
      digitRegex,
      digitRegex,
      separator,
      digitRegex,
      digitRegex,
      digitRegex,
      digitRegex,
    ]
  }

  // ###-###-####
  return [
    digitRegex,
    digitRegex,
    digitRegex,
    separator,
    digitRegex,
    digitRegex,
    digitRegex,
    separator,
    digitRegex,
    digitRegex,
    digitRegex,
    digitRegex,
  ]
}

type Props = {
  placeholder?: string
} & Component

export default function InputPhoneNumber({
  className,
  placeholder = '555-555-5555',
  ...props
}: Props) {
  return (
    <InputWithMask
      {...props}
      mask={mask}
      placeholder={placeholder}
      separator={separator}
      type="tel"
      className={classNames('InputPhoneNumber', className)}
    />
  )
}
