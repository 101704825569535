import classNames from 'classnames'
import React from 'react'
import Field2, { type Field2Props } from 'components/Field2'
import InputPhoneNumber from 'components/InputPhoneNumber'

type Props = Field2Props

export default function FieldPhoneNumber({ className, ...props }: Props) {
  return (
    <Field2
      as={InputPhoneNumber}
      autoComplete="tel-national"
      label={props.label || 'Phone'}
      name="phoneNumber"
      data-test-id="phone-input"
      {...props}
      className={classNames('FieldPhoneNumber', className)}
    />
  )
}
