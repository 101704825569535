import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Copy from 'components/Copy'
import Heading2 from 'components/Heading2'
import RequestDemoForm from 'components/RequestDemoForm'
import Row from 'components/Row'
import Text from 'components/Text'
import GatedResource from 'templates/GatedResource'

export default function RequestDemo() {
  const [showSuccess, setShowSuccess] = React.useState(false)

  function handleRequestDemoSuccess() {
    setShowSuccess(true)
  }

  const title = 'Request a demo'

  return (
    <GatedResource
      title="Join the movement for movement as medicine"
      formAlternate={<RequestDemoForm onSuccess={handleRequestDemoSuccess} />}
      formTitle="Request a meeting with Bold"
      hideDisclaimer
      resource={title}
      sidebar={
        showSuccess ? (
          <>
            <Heading2 levelStyle={4}>Thank you for requesting a meeting with Bold</Heading2>
            <Text element="p" flush size="large">
              We’ll be in touch soon.
            </Text>
          </>
        ) : undefined
      }
      className="RequestDemo">
      <Row>
        <Copy>
          <Text element="p">
            Give members something they’ll love (and actually use) while making an impact on the
            measures that most matter to your organization’s success.
          </Text>
        </Copy>
      </Row>
      <StaticImage src="./images/photo@2x.jpg" alt="" />
    </GatedResource>
  )
}
